import React, { useEffect, useState } from "react";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/UsecaesHeader";
import Container from "../../components/chat-channel/Container";
import AppsIntegrations from "../../components/common/usecases/appsIntegrations";
import ArrowRightICon from "../../components/common/Icons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LeftFormRightImage from "../../components/chat-channel/LeftFormRightImage";
import KeyResources from "../../components/common/KeyResources";
import Cards from "../../components/common/knowledge/Cards";

import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { Slider } from "../../components/common/sliderHomePage";
import {GoToPopup} from "../homepage"

export const blogImg = require("../../assets/img/integration/card_2.png");
export const whitepaperImg = require("../../assets/img/integration/card_4.png");
export const videoImg = require("../../assets/img/integration/video_card.png");

const section_1 = require("../../assets/images/usecases/desktop/usecase_icon_3.png");
const section_2 = require("../../assets/images/usecases/desktop/usecase_icon_4.png");
const section_3 = require("../../assets/images/usecases/desktop/usecase_icon_2.png");
const section_4 = require("../../assets/images/usecases/desktop/usecase_icon_1.png");

const section_1_mob = require("../../assets/images/usecases/mobile/usecase_icon_3_mob.png");
const section_2_mob = require("../../assets/images/usecases/mobile/usecase_icon_4_mob.png");
const section_3_mob = require("../../assets/images/usecases/mobile/usecase_icon_2_mob.png");
const section_4_mob = require("../../assets/images/usecases/mobile/usecase_icon_1_mob.png");

const slackteam = require("../../assets/images/usecases/onboarding/employee_onboarding_last.png");
const automation_steps = require("../../assets/images/usecases/onboarding/onboard_steps.png");
const mob_onboard_steps = require("../../assets/images/usecases/mobile/mob_onboard_steps.png");

const aboveFoldImage = require("../../assets/images/usecases/onboarding/employee_onboarding_header.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Build a contextual chatbot for employees with Workativ Assistant and Freshservice integration.",
    url:
      "https://blog.workativ.com/freshservice-integrations-automate-business-workflows-with-workativ-assistant/",
    link: "Read Blog",
    alt: "employee-onboarding-automation"
  },
  {
    image: whitepaperImg,
    header: "Reinventing the role of HR during the COVID-19 Pandemic ",
    url:
      "https://blog.workativ.com/reinventing-the-role-of-hr-during-the-covid-19-pandemic-business-continuity-planning-chatbots-and-whatnot/",
    link: "Read Blog",
    alt: "employee-onboarding-blog"
  },
  {
    image: videoImg,
    header: "How to create an intelligent Helpdesk Chatbot with Zero coding",
    url: "https://youtu.be/uMqbQ3A4h4E",
    link: "Watch Video",
    alt: "employee-onboarding-video"
  }
];
export const cardData = [
  {
    href: null,
    header: "Problem",
    content:
      "Not having a streamlined process for onboarding and the lack of end-to-end automation between IT and HR teams in the workplace.",
    backgroundClass: "card_one"
  },
  {
    href: null,
    header: "Solution",
    content:
      "Workativ integrates with popular IT and HR applications, and with AI and Automation, enables teams to complete the onboarding process faster.",
    backgroundClass: "card_two"
  },
  {
    href: null,
    header: "Impact",
    content:
      "Creating a good employee experience from the start for new hires ensures trust in the company, which is valuable for employee retention.",
    backgroundClass: "card_three"
  }
];

const headerMenu = [
  {
    menu: "Overview",
    href: "#section_1",
    id: "section_1"
  },
  {
    menu: "Why digital onboarding",
    href: "#section_2",
    id: "section_2"
  },
  {
    menu: "Onboarding automation steps",
    href: "#section_3",
    id: "section_3"
  },
  {
    menu: "Onboarding chatbot on Slack and Teams",
    href: "#section_4",
    id: "section_4"
  },
  {
    menu: " Other popular use cases",
    href: "#section_5",
    id: "section_3"
  }
];

const sectionImg = [
  {
    img: section_1,
    mobImage: section_1_mob,
    alt: "Digital onboarding solution",
    header: "Digital onboarding solution",
    paragraph:
      "Take the tediousness out of the onboarding process with a digital onboarding solution that we offer. Workativ integrates with popular workplace apps, enabling you to connect your HR, payroll, and IT apps to sync the new hires’ data with your central administrative accounts so that the employee data can be transferred and updated across all the apps seamlessly. From the get-go, employees would be able to transition to new processes without a hassle.  "
  },
  {
    img: section_2,
    mobImage: section_2_mob,
    alt: "Slack Onboarding",
    header: "Slack Onboarding",
    paragraph:
      "A helpdesk on Slack helps your employees get on board with company policies to understand the organization better. Especially with new hires, reaching out to HR is very common as they are not yet up to speed with requests and organizational policies. Let them access HR, by replacing your HR team with a chatbot on Slack to make the onboarding experience better.  "
  },
  {
    img: section_3,
    mobImage: section_3_mob,
    alt: "Onboarding Automation",
    header: "Onboarding Automation",
    paragraph:
      "Create automated workflows by integrating with existing apps on the Workativ automation platform to complete onboarding tasks. Help employees have essential information from the get-go. Workativ provides a platform to automate the employee onboarding process and create an environment for employees to get immediate responses to their doubts and questions by conversing with an HR chatbot. "
  },
  {
    img: section_4,
    mobImage: section_4_mob,
    alt: "Automation chatbot",
    header: "Automation chatbot",
    paragraph:
      "Provide a satisfactory onboarding experience to new hires, a step in the right direction to employee retention. Workativ integrates with popular HR software, ITSM software, and document management software to enable HR teams to save time with a streamlined onboarding process and create a good employee experience. With the Workativ onboarding chatbot, employees can get all the information they need without moving out of Slack and Teams. "
  }
];

export default function HomePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState("Overview");

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("Overview");

  const listenScrollEvent = e => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <>
      <TitleAndMetaTags
        title="Employee Onboarding Automation | HR workflow automation software | Onboarding Chatbot"
        description="Automate Employee Onboarding tasks for modern HR Teams. Deploy Onboarding automation chatbot on Slack and Microsoft Teams now."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={slackteam}
            />
      <Container>
      <Layout logoFor="ASSISTANT">
          <section className={header}>
            {/* <div className="container-fluid bg_apps_usecaes">
              <div className="row">
                <div className="container">
                  <div className="sticky_menu">
                    <span>Employee Onboarding:</span>
                    <ul>
                      {headerMenu.map(menu => (
                        <li>
                          <AnchorLink
                            offset={() => 160}
                            href={menu.href}
                            className={
                              isActive === menu.menu
                                ? "url_manipulation active"
                                : "url_manipulation "
                            }
                            onClick={() => {
                              setIsActive(menu.menu);
                            }}
                          >
                            {" "}
                            {menu.menu}
                          </AnchorLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

            <RegisterSection
              backgroundColor={"bg_apps_usecaes "}
              rightImage={aboveFoldImage}
              altImage={"Employee Onboarding Automation"}
              videoToken={"__NO_VIDEO_AVAILABLE__"}
              headerMenu={headerMenu}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Employee Onboarding Automation
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Automate onboarding tasks for modern HR teams and deliver a
                  positive onboarding experience for new hires.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            <div className="mob_sticky_wrapper">
              {/* <div className="dropdown wv-dropdown">
                <h4>Employee Onboarding:</h4>
                <button
                  className={`link ${isOpen ? "active" : ""}`}
                  onClick={() => setOpen(!isOpen)}
                >
                  {dropdown}
                </button>
                {headerMenu.map(menu => (
                  <div
                    onClick={() => setOpen(!isOpen)}
                    id="myDropdown"
                    className={`accordion-item submenu dropdown-content ${
                      !isOpen ? "collapsed" : ""
                    }`}
                  >
                    {" "}
                    <li className={menu.id}>
                      <AnchorLink
                        offset={460}
                        className="url_manipulation"
                        href={menu.href}
                        onClick={() => setDropdown(menu.menu)}
                      >
                        {menu.menu}
                      </AnchorLink>
                    </li>
                  </div>
                ))}
              </div> */}
              <AppsIntegrations
                header={"Employee Onboarding made easy with your favorite apps"}
                cards={appsName}
                id={"section_1"}
              />
              <section className="boarding_wrapper">
                <div className="container">
                  <div className="row">
                    {sectionImg.map((card, index) => {
                      if (index % 2 == 0) {
                        return (
                          <div className="boarding_wrapper_container">
                            <div className="row">
                              <div className="col-md-12 col-lg-3 col-12">
                                <picture>
                                  <source
                                    media="(max-width:768px)"
                                    srcSet={card.mobImage}
                                  />
                                  <img loading = "lazy" src={card.img} alt={card.alt}></img>
                                </picture>
                              </div>
                              <div className="col-md-12 col-lg-9 col-12 pad_left_30">
                                <h4>{card.header}</h4>
                                <p>{card.paragraph} </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div className="boarding_wrapper_container">
                          <div className="row">
                            <div className="col-md-12 col-lg-9 col-12 pad_right_30">
                              <h4>{card.header}</h4>
                              <p>{card.paragraph} </p>
                            </div>
                            <div className="col-md-12 col-lg-3 col-12">
                              <picture>
                                <source
                                  media="(max-width:768px)"
                                  srcSet={card.mobImage}
                                />
                                <img loading = "lazy" src={card.img} alt={card.alt}></img>
                              </picture>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Cards
                id={"section_2"}
                cards={cardData}
                header={"Why digital onboarding"}
              />
              <section className="automation_steps" id={"section_3"}>
                <div className="container">
                  <h2>How Employee Onboarding automation works</h2>

                  <picture>
                    <source
                      media="(max-width:768px)"
                      srcSet={mob_onboard_steps}
                    />
                    <img loading = "lazy"
                      src={automation_steps}
                      alt="How Employee Onboarding automation works"
                    />
                  </picture>
                </div>
              </section>
              <section className="slack_teams_wrapper" id={"section_4"}>
                <LeftFormRightImage
                  image={slackteam}
                  altImage={"Employee Onboarding Chatbot"}
                >
                  <LeftFormRightImage.Header>
                    <h3>Onboarding chatbot on Slack and Microsoft Teams</h3>
                  </LeftFormRightImage.Header>
                  <LeftFormRightImage.SubHeader>
                    <p>
                      Make use of your Slack and MS Teams channels to make
                      employee onboarding more friendly and remove the first
                      day’s anxieties at work. For information on company
                      policies or the handbook, employees can use Slack or
                      Teams’ familiar interface for access to important
                      documents without having to approach the HR team. Deliver
                      the perfect onboarding chatbot from Workativ to create a
                      delightful onboarding experience for new employees.
                    </p>
                  </LeftFormRightImage.SubHeader>
                  <LeftFormRightImage.Link>
                    <div className="card_link_landing">
                      <SlackAndTeams />
                    </div>
                  </LeftFormRightImage.Link>
                </LeftFormRightImage>
              </section>
              <Slider
                title={"Other popular use cases"}
                sliderData={sliderData}
                id={"section_5"}
              />
            </div>

            <div className="popular_workflows_">
            <KeyResources cards={resoucesData} />
            </div>
          </section>
          {/* <GoToPopup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

export const leave = require("../../assets/images/usecases/common/leave_management.png");
export const offboarding = require("../../assets/images/usecases/common/offboarding.png");
export const payroll = require("../../assets/images/usecases/common/payroll.png");
export const reset = require("../../assets/images/usecases/common/reset.png");
export const ticketing = require("../../assets/images/usecases/common/ticketing.png");
export const unlock = require("../../assets/images/usecases/common/unlock.png");
export const user_provision = require("../../assets/images/usecases/common/user_provision.png");

const sliderData = [
  {
    header: "Employee Offboarding",
    alt: "Employee Offboarding",
    image: offboarding,
    content:
      "Automate employee offboarding and remove the risk of ex-employees’ access to your systems and network ",
    link: "Learn more",
    url: "/use-cases/employee-offboarding-automation"
  },
  {
    header: "Unlock Account",
    alt: "Unlock Account Automation",
    image: unlock,
    content:
      "Strengthen enduser security and minimize calls to the helpdesk with ‘unlock account’ automation.",
    link: "Learn more",
    url: "/use-cases/unlock-account-automation"
  },
  {
    header: "Password Reset",
    alt: "Password Reset Automation",
    image: reset,
    content:
      "Expedite issue resolution for employees and reduce service desk call volume with ‘password reset’ automation.",
    link: "Learn more",
    url: "/use-cases/reset-password-automation"
  },

  {
    header: "Payroll Enquiry",
    alt: "Payroll Enquiry Automation",
    image: payroll,
    content:
      "Streamline automation for delivering payroll information to employees with zero waiting time.",
    link: "Learn more",
    url: "/use-cases/payroll-enquiry-automation"
  },
  {
    header: "AI Ticketing",
    alt: "AI Ticketing Automation",
    image: ticketing,
    content:
      "Reduce contact with the helpdesk for repetitive issues and transform the employee experience with AI.",
    link: "Learn more",
    url: "/use-cases/ai-ticketing"
  },
  {
    header: "User Provisioning",
    alt: "User Provisioning Automation",
    image: user_provision,
    content:
      "Reduce costs and improve employee experience with user provisioning automation.",
    link: "Learn more",
    url: "/use-cases/user-provisioning-automation"
  },
  {
    header: "Leave management",
    alt: "Leave management automation",
    image: leave,
    content:
      "Simplify PTO inquiries and requests for employees with an automated, intelligent and user-friendly chatbot.",
    link: "Learn more",
    url: "/use-cases/leave-management-automation"
  }
];

const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const ms_office = require("../../assets/images/icons-web/ms_office.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const fd = require("../../assets/images/icons-web/fd_1.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const people_hr = require("../../assets/images/icons-web/people-hr.svg");
const purelyhr = require("../../assets/images/icons-web/purelyhr.svg");
const zohodesk = require("../../assets/images/icons-web/zohodesk.svg");

const appsName = [
  {
    image: bamboo,
    alt: "bamboo"
  },
  {
    image: ms_office,
    alt: "ms_office"
  },
  {
    image: freshservice,
    alt: "freshservice"
  },
  {
    image: zoho_people,
    alt: "Zoho People"
  },
  {
    image: fd,
    alt: "Freshdesk"
  },
  {
    image: people_hr,
    alt: "people_hr"
  },
  {
    image: purelyhr,
    alt: "people_hr"
  },
  {
    image: zohodesk,
    alt: "zohodesk"
  }
];

export const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
