import React, { useEffect } from "react";
import ArrowRightICon from "../../components/common/Icons";
import * as $ from "jquery";
if (typeof window !== `undefined`) {
  require("../../assets/js/slick.js");
  require("../../assets/styles/slick.css");
  require("../../assets/styles/slick-theme.css");
}

export function Slider(props) {
  useEffect(() => {
    setTimeout(
      () =>
        $(".slick-carousel").slick({
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: true,
          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3.1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2.8,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1.1,
                slidesToScroll: 1
              }
            }
          ]
        }),
      0
    );
  }, []);

  const { sliderData, title, marketplaceTitle, id } = props;
  const additionalClass = props.additionalClass;
  return (
    <section className="featured_content" id={id}>
      <div className="container">
        <div className="drag">Drag</div>
        <h3>{title}</h3>
        <div className="left-whitespace" />
        <div className="slick-carousel">
          {sliderData.map((slider, i) => (
            <div className="cards_slider" key={i}>
              <div className="benefits-workplace-content cogni_workspace_content home_slider">
                <div className={`${"integrations_card_slider "}${
        additionalClass ? additionalClass : ""
      }`}>
                  <img src={slider.image} alt={slider.alt} />
                </div>
              <div className={slider.backgroundClass}>
                <h3>{slider.header}</h3>
                <p className="font-section-normal-text line-3-ellipsis">{slider.content}</p>
                <div className="home_link">
                  <a href={slider.url} className="text-decoration-underline text-underline-offset color-anchor-blue font-section-normal-text-medium-small">{slider.link}&nbsp;&#8594;</a>
                 
                </div>
             </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="card_link_landing">
          <a href="/conversational-ai-platform/marketplace" className="url_manipulation">
            {marketplaceTitle ? marketplaceTitle : "Automations Marketplace"}{" "}
          </a>
        </div> */}
      </div>
    </section>
  );
}
